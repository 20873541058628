// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SearchInput_searchInput__WhD5t {\n    /* border-radius: 50px !important; */\n    padding: .375rem 1.5rem !important;\n}\n\n.SearchInput_searchIcon__6sjBI {\n    position: absolute;\n    top: 7px;\n    left: 3px;\n    display: flex;\n}\n\n.SearchInput_search__VdYgM{\n\n     padding-left:2rem !important\n\n}\n.SearchInput_iconBorder__-H2Ju {\n    padding: 6px;\n    border: 1px solid;\n    border-radius: 6px;\n}\n\n\n@media screen and (max-width: 768px) {\n    .SearchInput_searchIcon__6sjBI {\n        color: #9537FF;\n        position: relative;\n        top: 1px;\n        left: 1px;\n        display: none !important;\n    }\n    .SearchInput_searchInput__WhD5t {\n        /* border-radius: 50px !important; */\n        padding: .375rem 1.5rem !important;\n    }\n}", "",{"version":3,"sources":["webpack://./src/styles/SearchInput.module.css"],"names":[],"mappings":"AAAA;IACI,oCAAoC;IACpC,kCAAkC;AACtC;;AAEA;IACI,kBAAkB;IAClB,QAAQ;IACR,SAAS;IACT,aAAa;AACjB;;AAEA;;KAEK;;AAEL;AACA;IACI,YAAY;IACZ,iBAAiB;IACjB,kBAAkB;AACtB;;;AAGA;IACI;QACI,cAAc;QACd,kBAAkB;QAClB,QAAQ;QACR,SAAS;QACT,wBAAwB;IAC5B;IACA;QACI,oCAAoC;QACpC,kCAAkC;IACtC;AACJ","sourcesContent":[".searchInput {\n    /* border-radius: 50px !important; */\n    padding: .375rem 1.5rem !important;\n}\n\n.searchIcon {\n    position: absolute;\n    top: 7px;\n    left: 3px;\n    display: flex;\n}\n\n.search{\n\n     padding-left:2rem !important\n\n}\n.iconBorder {\n    padding: 6px;\n    border: 1px solid;\n    border-radius: 6px;\n}\n\n\n@media screen and (max-width: 768px) {\n    .searchIcon {\n        color: #9537FF;\n        position: relative;\n        top: 1px;\n        left: 1px;\n        display: none !important;\n    }\n    .searchInput {\n        /* border-radius: 50px !important; */\n        padding: .375rem 1.5rem !important;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"searchInput": "SearchInput_searchInput__WhD5t",
	"searchIcon": "SearchInput_searchIcon__6sjBI",
	"search": "SearchInput_search__VdYgM",
	"iconBorder": "SearchInput_iconBorder__-H2Ju"
};
export default ___CSS_LOADER_EXPORT___;
