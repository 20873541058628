// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Login_wrapper__ZcalN {\n  height: 90vh;\n}\n\n.Login_passwordInput__8vrwu {\n  position: relative;\n}\n\n.Login_passwordInput__8vrwu svg {\n  bottom: 13px;\n  position: absolute;\n  right: 10px;\n  width: 16px;\n}\n\n.Login_passwordField__bm2ag {\n  position: absolute;\n  display: flex;\n  justify-content: flex-end;\n  width: 100%;\n}\n\n.Login_passwordInput__8vrwu input {\n  padding-right: 30px !important;\n}\n.Login_passwordInput__8vrwu .form-control:invalid {\n  background-position: right calc(0.375em + 1.1875rem) center !important;\n}\n", "",{"version":3,"sources":["webpack://./src/styles/Login.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;AACd;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,YAAY;EACZ,kBAAkB;EAClB,WAAW;EACX,WAAW;AACb;;AAEA;EACE,kBAAkB;EAClB,aAAa;EACb,yBAAyB;EACzB,WAAW;AACb;;AAEA;EACE,8BAA8B;AAChC;AACA;EACE,sEAAsE;AACxE","sourcesContent":[".wrapper {\n  height: 90vh;\n}\n\n.passwordInput {\n  position: relative;\n}\n\n.passwordInput svg {\n  bottom: 13px;\n  position: absolute;\n  right: 10px;\n  width: 16px;\n}\n\n.passwordField {\n  position: absolute;\n  display: flex;\n  justify-content: flex-end;\n  width: 100%;\n}\n\n.passwordInput input {\n  padding-right: 30px !important;\n}\n.passwordInput :global(.form-control):invalid {\n  background-position: right calc(0.375em + 1.1875rem) center !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "Login_wrapper__ZcalN",
	"passwordInput": "Login_passwordInput__8vrwu",
	"passwordField": "Login_passwordField__bm2ag"
};
export default ___CSS_LOADER_EXPORT___;
