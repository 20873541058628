// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ImageUploader_border__GEfHT {\n    border: 1px dashed #DEDFE4;\n    border-radius: 6px;\n    gap: 15px\n}\n\n.ImageUploader_browseTxt__tS8ou {\n    color: rgba(89, 50, 234, 1);\n}\n\n.ImageUploader_txt__dRLU0 {\n    font-size: 14px;\n}\n\n.ImageUploader_txtSize__qxzPL {\n    font-size: 12px;\n    display: block;\n}\n\n.ImageUploader_image__VKZ38 {\n    margin: 0.5rem 0.5rem;\n    position: relative;\n    box-shadow: rgba(0,0,0,0.05) 0px 1px 2px 0px;\n    width: 10rem;\n    height: 10rem;\n    border-radius: 5px;\n    \n    /* border: 0.5px solid rgba(0,0,0,0.12); */\n}", "",{"version":3,"sources":["webpack://./src/Components/ImageUploader/ImageUploader.module.css"],"names":[],"mappings":"AAAA;IACI,0BAA0B;IAC1B,kBAAkB;IAClB;AACJ;;AAEA;IACI,2BAA2B;AAC/B;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,eAAe;IACf,cAAc;AAClB;;AAEA;IACI,qBAAqB;IACrB,kBAAkB;IAClB,4CAA4C;IAC5C,YAAY;IACZ,aAAa;IACb,kBAAkB;;IAElB,0CAA0C;AAC9C","sourcesContent":[".border {\n    border: 1px dashed #DEDFE4;\n    border-radius: 6px;\n    gap: 15px\n}\n\n.browseTxt {\n    color: rgba(89, 50, 234, 1);\n}\n\n.txt {\n    font-size: 14px;\n}\n\n.txtSize {\n    font-size: 12px;\n    display: block;\n}\n\n:local(.image) {\n    margin: 0.5rem 0.5rem;\n    position: relative;\n    box-shadow: rgba(0,0,0,0.05) 0px 1px 2px 0px;\n    width: 10rem;\n    height: 10rem;\n    border-radius: 5px;\n    \n    /* border: 0.5px solid rgba(0,0,0,0.12); */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"border": "ImageUploader_border__GEfHT",
	"browseTxt": "ImageUploader_browseTxt__tS8ou",
	"txt": "ImageUploader_txt__dRLU0",
	"txtSize": "ImageUploader_txtSize__qxzPL",
	"image": "ImageUploader_image__VKZ38"
};
export default ___CSS_LOADER_EXPORT___;
