// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Shopkeepers_navTabs__Mo\\+Fs .nav-link {\n    background-color: #FFFFFF !important;\n    color: rgba(149, 55, 255, 1) !important;\n    padding: 7px !important;\n    font-size: 14px;\n    border: 1px solid rgba(222, 223, 228, 1) !important;\n}\n\n.Shopkeepers_navTabs__Mo\\+Fs .nav-link.active {\n    background-color: rgba(149, 55, 255, 1) !important;\n    color: #FFFFFF !important;\n}\n\n\n@media screen and (max-width: 820px) {\n    .Shopkeepers_flexGap__0sWY8 {\n        margin-bottom: 8px;\n    }\n}", "",{"version":3,"sources":["webpack://./src/styles/Shopkeepers.module.css"],"names":[],"mappings":"AAAA;IACI,oCAAoC;IACpC,uCAAuC;IACvC,uBAAuB;IACvB,eAAe;IACf,mDAAmD;AACvD;;AAEA;IACI,kDAAkD;IAClD,yBAAyB;AAC7B;;;AAGA;IACI;QACI,kBAAkB;IACtB;AACJ","sourcesContent":[".navTabs :global(.nav-link) {\n    background-color: #FFFFFF !important;\n    color: rgba(149, 55, 255, 1) !important;\n    padding: 7px !important;\n    font-size: 14px;\n    border: 1px solid rgba(222, 223, 228, 1) !important;\n}\n\n.navTabs :global(.nav-link.active) {\n    background-color: rgba(149, 55, 255, 1) !important;\n    color: #FFFFFF !important;\n}\n\n\n@media screen and (max-width: 820px) {\n    .flexGap {\n        margin-bottom: 8px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"navTabs": "Shopkeepers_navTabs__Mo+Fs",
	"flexGap": "Shopkeepers_flexGap__0sWY8"
};
export default ___CSS_LOADER_EXPORT___;
